import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import MiniCard from '../MiniCard/MiniCard.js';
import {
  getCardsForSeat,
  arrangeSeatsBasedOnViewPoint
} from '../../../helpers/card.js';

const styles = {
  component: {
    position: 'relative',
    width: '13.9em',
    height: '11em',
    // if any of the DealDirections have wrapping rows
    // this makes sure the trick stays in the middle
    margin: 'auto 0'
  },
  index0: {
    position: 'absolute',
    left: '4.3em',
    top: 0
  },
  index1: {
    position: 'absolute',
    right: 0,
    top: '3.7em'
  },
  index2: {
    position: 'absolute',
    left: '4.3em',
    bottom: 0
  },
  index3: {
    position: 'absolute',
    left: 0,
    top: '3.7em'
  }
};

const Trick = (props) => {
  const { classes, scale, trick, viewpoint } = props;
  const directions = arrangeSeatsBasedOnViewPoint(viewpoint);
  return (
    <div className={classes.component}>
      {
        directions.map((direction, index) => {
          const card = getCardsForSeat(trick, direction.value)[0];
          return (
            <div key={index} className={classes[`index${index}`]}>
              <MiniCard
                scale={scale}
                suit={card ? card.suit : null}
                value={card ? card.rank : null}
                isWinner={card ? card.winner : null}
                isBid
              />
            </div>
          );
        })
      }
    </div>
  );
};

Trick.propTypes = {
  classes: PropTypes.object.isRequired,
  scale: PropTypes.number,
  trick: PropTypes.array.isRequired,
  viewpoint: PropTypes.string.isRequired
};

export default withStyles(styles)(Trick);

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Grid
} from '@mui/material';
import DealDirection from './DealDirection.js';
import { getCardsForSeat, arrangeSeatsBasedOnViewPoint } from '../../../helpers/card.js';

const useStyles = makeStyles(() => ({
  paddedBox: {
    padding: '.5rem 1.5rem .5rem 1.5rem'
  },
  paddedBoxWide: {
    padding: '1.5rem .5rem 1.5rem .5rem'
  }
}));

const Deal = (props) => {
  const {
    viewpoint,
    wideLayout,
    activeDirection,
    dealer,
    children,
    vulnerable,
    cards,
    ...other
  } = props;
  const directions = arrangeSeatsBasedOnViewPoint(viewpoint);

  const classes = useStyles();

  const dealDirections = directions.map((direction, index) => {
    const cardsForSeat = getCardsForSeat(cards, direction.value);
    const isActiveDirection = direction.value === activeDirection;
    return (
      <DealDirection
        key={index}
        direction={direction.key}
        active={isActiveDirection}
        dealer={dealer === direction.value}
        vulnerable={
        !!((vulnerable === 'Both' || vulnerable.includes(direction.value)) &&
          vulnerable !== 'None')
        }
        inactive={activeDirection !== 'None' && !isActiveDirection}
        cards={cardsForSeat}
        {...other}
      />
    );
  });

  let paddingClass;
  if (children) {
    if (wideLayout) {
      paddingClass = classes.paddedBoxWide;
    } else {
      paddingClass = classes.paddedBox;
    }
  }

  return (
    <Grid
      container
      direction={wideLayout ? 'row' : 'column'}
      justifyContent='center'
      alignItems='center'
    >
      <Grid item className={paddingClass}>
        {dealDirections[wideLayout ? 3 : 0]}
      </Grid>
      <Grid item>
        <Grid
          container
          direction={wideLayout ? 'column' : 'row'}
          justifyContent='center'
          alignItems='stretch'
        >
          <Grid item className={paddingClass}>
            {dealDirections[wideLayout ? 0 : 3]}
          </Grid>
          {/* CHILDREN */}
          {
            children
              ? <>{children}</>
              : null
          }
          <Grid item className={paddingClass}>
            {dealDirections[wideLayout ? 2 : 1]}
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={paddingClass}>
        {dealDirections[wideLayout ? 1 : 2]}
      </Grid>
    </Grid>
  );
};

Deal.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.object,
  selectable: PropTypes.bool,
  expanded: PropTypes.bool,
  wideLayout: PropTypes.bool,
  wrap: PropTypes.bool,
  dealer: PropTypes.string.isRequired,
  viewpoint: PropTypes.string.isRequired,
  vulnerable: PropTypes.string.isRequired,
  activeDirection: PropTypes.string,
  cards: PropTypes.array.isRequired,
  allPlayed: PropTypes.bool,
  onSeatClick: PropTypes.func,
  onCardClick: PropTypes.func,
  currentHand: PropTypes.array
};

export default Deal;

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import DealDirectionHeader from './DealDirectionHeader.js';
import DealDirectionBody from './DealDirectionBody.js';

const styles = {
  component: {
    lineHeight: '1em',
    minHeight: '11.6em',
    background: '#fff',
    border: '0.1em solid #ccc',
    borderRadius: '0.3em',
    color: '#4a4a4a',
    overflow: 'hidden',
    fontWeight: 600,
    userSelect: 'none',
    height: '100%',
    width: '16em'
  },
  dimmed: {
    opacity: 0.7
  },
  highlighted: {
    borderColor: '#4a90e2',
    zIndex: 1,
    boxShadow: '0 0 0 0.1em #4a90e2, 0 0.2em 0.5em rgba(74, 144, 226, 0.25)',
    opacity: 1
  }
};

const DealDirection = (props) => {
  const {
    classes,
    index,
    active,
    inactive,
    selectable,
    ...other
  } = props;

  return (
    <div
      className={
        clsx(
          classes.component,
          active ? classes.highlighted : null,
          inactive ? classes.dimmed : null
        )
      }
    >
      <DealDirectionHeader {...other} />
      <DealDirectionBody
        selectable={selectable && active}
        {...other}
      />
    </div>
  );
};

DealDirection.propTypes = {
  classes: PropTypes.object.isRequired,
  selectable: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  inactive: PropTypes.bool,
  direction: PropTypes.string.isRequired,
  dealer: PropTypes.bool.isRequired,
  vulnerable: PropTypes.bool.isRequired,
  cards: PropTypes.array.isRequired,
  allPlayed: PropTypes.bool,
  onSeatClick: PropTypes.func,
  onCardClick: PropTypes.func,
  currentHand: PropTypes.array
};

export default withStyles(styles)(DealDirection);
